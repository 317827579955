import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from '@/i18n'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import helper from '@/helper'
import './assets/scss/style.scss'
import vueCountryRegionSelect from 'vue-country-region-select'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VCalendar from 'v-calendar'

Vue.use(VueTheMask)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VCalendar, {
    componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
// see docs for available options
const datepickerOptions = {
    sundayFirst: true,
    colors: {
        selected: '#00a699',
        inRange: '#66e2da',
        selectedText: '#fff',
        text: '#565a5c',
        inRangeBorder: '#33dacd',
        disabled: '#fff',
        hoveredInRange: '#67f6ee'
    }
}
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
Vue.use(vueCountryRegionSelect)
let apiUrl = ''
let domain = ''
if (location.hostname === 'localhost') {
    apiUrl = 'http://localhost/works/HosBookEngine/api/'
    domain = 'test'
} else if (location.hostname === 'test.hosbooking.net') {
    apiUrl = 'https://testapi.hosbooking.com/'
    domain = 'test'
} else {
    apiUrl = 'https://api.hosbooking.com/'
    domain = location.hostname.slice(0, location.hostname.indexOf('.'))
}

axios.defaults.baseURL = apiUrl
axios.interceptors.request.use(function (config) {
    config.headers['Accept-Language'] = i18n.locale
    config.headers.Token = 'front'
    config.headers.Domain = domain
    config.headers.Authorization = localStorage.getItem('HosCustomerAuthToken')
    return config
}, function (error) {
    return Promise.reject(error)
})
Vue.prototype.$domain = domain
Vue.prototype.$http = axios
Vue.prototype.$helper = helper
Vue.prototype.$googleMapKey = 'AIzaSyCwG5n1sOX3isKP0qJcNBdpXDSLAz3ooUw'

const swalOptions = {
    confirmButtonColor: '#00b74a',
    cancelButtonColor: '#ff7674',
    reverseButtons: true,
    heightAuto: false
}
Vue.use(VueSweetalert2, swalOptions)
const moment = require('moment')
if (i18n.locale === 'de') {
    require('moment/locale/de')
}
Vue.use(require('vue-moment'), {
    moment
})

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
